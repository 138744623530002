import 'normalize.css'
import '@assets/styles/main.scss'
import { AppProps } from 'next/app'
import { FC } from 'react'
import { UIProvider } from '@components/ui/context'
import Head from 'next/head'
import { ApiProvider } from '@framework'

const Noop: FC = ({ children }) => <>{children}</>

const BuildProviderTree: any = (providers: any[]) => {
  if (providers.length === 1) {
    return providers[0]
  }
  const A = providers.shift()
  const B = providers.shift()
  return BuildProviderTree([
    ({ children }: any) => (
      <A>
        <B>{children}</B>
      </A>
    ),
    ...providers,
  ])
}

function MyApp({
  Component,
  pageProps,
}: AppProps & { Component: { Layout: FC } }) {
  const Layout = Component.Layout ?? Noop
  const Providers = BuildProviderTree([UIProvider, ApiProvider])
  return (
    <Providers>
      <Head>
        <link rel="stylesheet" href="https://use.typekit.net/qta7zws.css" />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Providers>
  )
}

export default MyApp
