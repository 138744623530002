import {
  Checkout,
  CheckoutLineItemEdge,
  MediaEdge,
  MediaImage,
  Video,
  ExternalVideo,
  MoneyV2,
  Product as ShopifyProduct,
  ProductOption,
  ProductVariantConnection,
  SelectedOption,
  Page as ShopifyPage,
  Collection as ShopifyCollection,
} from '../schema'
import { Metafields } from '@framework/types/metafields'
import { Cart, LineItem } from '@common/types/cart'
import {
  ProductMediaType,
  ProductImage,
  ProductVideo,
  ProductExternalVideo,
  ProductNotAvailableOnline,
  ProductNotAvailableOnlineReason,
  ProductNotAvailableOnlineContactType,
} from '@common/types/product'
import { productSpecsMetafields } from '@framework/utils/common'
import { formatPrice } from '@common/product/use-price'
export const normalizeCart = (checkout: Checkout): Cart => {
  return {
    id: checkout.id,
    createdAt: checkout.createdAt,
    completedAt: checkout.completedAt,
    currency: {
      code: checkout.totalPriceV2.currencyCode,
    },
    taxesIncluded: checkout.taxesIncluded,
    lineItemsSubtotalPrice: +checkout.subtotalPriceV2.amount,
    totalPrice: checkout.totalPriceV2.amount,
    totalTax: checkout.totalTaxV2.amount,
    lineItems: checkout.lineItems.edges.map(normalizeLineItem),
    discounts: [],
  }
}

const normalizeLineItem = ({
  node: { id, title, variant, ...rest },
}: CheckoutLineItemEdge): LineItem => {
  return {
    id,
    variantId: String(variant?.id),
    productId: String(variant?.id),
    availableForSale: variant?.availableForSale,
    quantityAvailable: variant?.quantityAvailable,
    name: title,
    path: variant?.product?.handle ?? '',
    discounts: [],
    options: variant?.title == 'Default Title' ? [] : variant?.selectedOptions,
    variant: {
      id: String(variant?.id),
      sku: variant?.sku ?? '',
      name: variant?.title,
      image: {
        type: ProductMediaType.Image,
        url:
          process.env.NEXT_PUBLIC_FRAMEWORK === 'shopify_local'
            ? `/static_images/${variant?.image?.originalSrc}`
            : variant?.image?.originalSrc ??
              '/static_images/ts-pool-supply-square-pool.jpg',
      },
      requiresShipping: variant?.requiresShipping ?? false,
      price: variant?.priceV2.amount,
      listPrice: variant?.compareAtPriceV2?.amount,
    },
    ...rest,
  }
}

const normalizeProductMedia = ({ edges }: { edges: Array<MediaEdge> }) =>
  edges.map(({ node }: MediaEdge) => {
    switch (node.mediaContentType) {
      case 'VIDEO':
        return normalizeProductVideo(node as Video)
      case 'EXTERNAL_VIDEO':
        return normalizeProductExternalVideo(node as ExternalVideo)
      case 'IMAGE':
        return normalizeProductImage(node as MediaImage)
    }
  })

const normalizeProductVideo = ({ alt, sources }: Video): ProductVideo => {
  return {
    type: ProductMediaType.Video,
    alt: alt ?? '',
    sources: sources.map((source) => {
      return {
        url: source.url ?? '',
        height: source.height ?? 0,
        width: source.width ?? 0,
        mimeType: source.mimeType ?? '',
      }
    }),
  }
}

const normalizeProductExternalVideo = ({
  alt,
  embedUrl,
}: ExternalVideo): ProductExternalVideo => {
  return {
    type: ProductMediaType.ExternalVideo,
    alt: alt ?? '',
    url: embedUrl ?? '',
  }
}

const normalizeProductImage = ({ alt, image }: MediaImage): ProductImage => {
  return {
    type: ProductMediaType.Image,
    alt: alt ?? '',
    url: image?.originalSrc ?? '',
    height: image?.width ?? 0,
    width: image?.width ?? 0,
  }
}

const normalizeProductPrice = ({ currencyCode, amount }: MoneyV2) => ({
  value: +amount,
  currencyCode,
})

const normalizeProductOption = ({
  id,
  values,
  name: displayName,
}: ProductOption) => {
  const normalized = {
    id,
    displayName,
    values: values.map((value) => {
      let output: any = {
        label: value,
      }

      return output
    }),
  }

  return normalized
}

const normalizeProductVariants = ({ edges }: ProductVariantConnection) => {
  return edges.map(({ node }) => {
    const {
      id,
      selectedOptions,
      sku,
      title,
      priceV2,
      compareAtPriceV2,
      availableForSale,
      quantityAvailable,
    } = node

    return {
      id,
      name: title,
      sku: sku || id,
      price: +priceV2.amount,
      listPrice: +compareAtPriceV2?.amount,
      availableForSale,
      quantityAvailable,
      requiresShipping: true,
      options: selectedOptions.map(({ name, value }: SelectedOption) => {
        const option = normalizeProductOption({
          id,
          name,
          values: [value],
        })

        return option
      }),
    }
  })
}

export function normalizeProduct(
  productNode: ShopifyProduct & Metafields
): any {
  const {
    id,
    totalInventory,
    availableForSale,
    title: name,
    handle,
    vendor,
    description,
    descriptionHtml,
    updatedAt,
    media,
    priceRange,
    compareAtPriceRange,
    options,
    variants,
    manufacturerUrl,
    callForPricing,
    emailForPricing,
    beatCompetitorsPrice,
    requestInstall,
    discountOff,
    unadvertisedPrice,
    inStoreOnly,
    discountDollar,
    discountPercentage,
    collections,
    seo,
    ...rest
  } = productNode

  let notAvailableOnline = null
  let specialPrice = false
  let hasDiscountOff = null
  let hasBeatCompetitorsPrice = false
  let hasRequestInstall = false

  if (unadvertisedPrice?.value && String(unadvertisedPrice.value) === 'true') {
    specialPrice = true
  }

  if (discountOff?.value && parseInt(discountOff.value) > 0) {
    hasDiscountOff = discountOff.value
  }

  if (beatCompetitorsPrice?.value && String(beatCompetitorsPrice.value) === 'true') {
    hasBeatCompetitorsPrice = true
  }

  if (requestInstall?.value && String(requestInstall.value) === 'true') {
    hasRequestInstall = true
  }

  if (callForPricing?.value && String(callForPricing.value) === 'true') {
    notAvailableOnline = {
      reasonText: ProductNotAvailableOnlineReason.CallForPrice,
      contactType: ProductNotAvailableOnlineContactType.Call,
      showPrice: false,
    }
  } else if (
    emailForPricing?.value &&
    String(emailForPricing.value) === 'true'
  ) {
    notAvailableOnline = {
      reasonText: ProductNotAvailableOnlineReason.EmailForPrice,
      contactType: ProductNotAvailableOnlineContactType.Email,
      showPrice: false,
    }
  } else if (inStoreOnly?.value && String(inStoreOnly.value) === 'true') {
    notAvailableOnline = {
      reasonText: ProductNotAvailableOnlineReason.InStoreOnly,
      contactType: ProductNotAvailableOnlineContactType.Email,
      showPrice: false,
    }
  } else if (discountDollar?.value) {
    notAvailableOnline = {
      reasonText: ProductNotAvailableOnlineReason.DiscountDollar.replace(
        '*',
        formatPrice({
          amount: parseFloat(discountDollar?.value),
          currencyCode: 'USD',
          locale: 'en-US',
        })
      ),
      contactType: ProductNotAvailableOnlineContactType.Email,
      showPrice: false,
    }
  } else if (discountPercentage?.value) {
    notAvailableOnline = {
      reasonText: ProductNotAvailableOnlineReason.DiscountPercentage.replace(
        '*',
        discountPercentage?.value
      ),
      contactType: ProductNotAvailableOnlineContactType.Email,
      showPrice: false,
    }
  }

  const product = {
    id,
    totalInventory,
    availableForSale: String(availableForSale) === 'true',
    name,
    vendor,
    description: description,
    descriptionHtml: descriptionHtml,
    updatedAt: updatedAt,
    path: `/${handle}`,
    slug: handle.replace(/^\/+|\/+$/g, ''),
    media: media ? normalizeProductMedia(media) : [],
    //images: normalizeProductImages(imageConnection),
    price: normalizeProductPrice(priceRange.minVariantPrice),
    // By default Shopify adds a 'Title' name when there's only one option. We don't need it.
    // https://community.shopify.com/c/Shopify-APIs-SDKs/Adding-new-product-variant-is-automatically-adding-quot-Default/td-p/358095
    options: options
      ? options
          .filter((o) => o.name !== 'Title')
          .map((o) => normalizeProductOption(o))
      : [],
    variants: variants ? normalizeProductVariants(variants) : [],
    manufacturerUrl: manufacturerUrl?.value ? manufacturerUrl.value : '',
    notAvailableOnline,
    specialPrice,
    hasDiscountOff,
    hasBeatCompetitorsPrice,
    hasRequestInstall,
    collections: collections
      ? collections.edges.map((c) =>
          normalizeCollection(c.node as ShopifyCollection & Metafields)
        )
      : [],
    seo: seo ? {
        title: seo.title || '',
        description: seo.description || '',
    } : { title: '', description: '' },
  }

  let productSpecs: any = {}

  productSpecsMetafields.map((spec) => {
    if (spec.key in rest && rest[spec.key]?.value) {
      productSpecs[spec.key] = rest[spec.key].value
    } else {
      productSpecs[spec.key] = null
    }
  })

  return Object.assign(product, productSpecs)
}

export function normalizePage(pageNode: ShopifyPage): any {
  const { id, body, handle, seo, ...rest } = pageNode

  const page = {
    id,
    body,
    path: `/${handle}`,
    slug: handle.replace(/^\/+|\/+$/g, ''),
    seo,
    ...rest,
  }

  return page
}

export function normalizeCollection(
  collectionNode: ShopifyCollection & Metafields
): any {
  const {
    id,
    title,
    handle,
    description,
    image,
    category,
    color,
    sort_order,
    products,
    seo,
    ...rest
  } = collectionNode

  const collection = {
    id,
    title,
    path: `${handle}`,
    slug: handle.replace(/^\/+|\/+$/g, ''),
    description,
    image: image?.originalSrc ?? '',
    category: category?.value ? String(category.value) === 'true' : '',
    color: color?.value ? color.value : '',
    sort_order: sort_order?.value ? Number(sort_order.value) : 999999999,
    products: products
      ? products.edges.map(({ node: product }) =>
          normalizeProduct(product as ShopifyProduct & Metafields)
        )
      : [],
    seo: seo ? {
      title: seo.title || '',
      description: seo.description || '',
    } : { title: '', description: '' },
    ...rest,
  }

  return collection
}
