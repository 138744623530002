import React, { FC, useCallback, useMemo } from 'react'

export interface State {
  displaySidebar: boolean
  displayChecklist: boolean
  displayDiscount: boolean,
  displayCompetitor: boolean,
  displayUnadvertised: boolean,
  displayChecklistSuccess: boolean
  displayDropdown: boolean
  displayModal: boolean
  sidebarView: string
  modalView: string
  userAvatar: string
}

const initialState = {
  displaySidebar: false,
  displayChecklist: false,
  displayDiscount: false,
  displayCompetitor: false,
  displayUnadvertised: false,
  displayChecklistSuccess: false,
  displayDropdown: false,
  displayModal: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: '',
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_CHECKLIST'
    }
  | {
      type: 'CLOSE_CHECKLIST'
    }
  | {
      type: 'OPEN_DISCOUNT'
    }
  | {
      type: 'CLOSE_DISCOUNT'
    }
  | {
      type: 'OPEN_COMPETITOR'
    }
  | {
      type: 'CLOSE_COMPETITOR'
    }
  | {
      type: 'OPEN_UNADVERTISED'
    }
  | {
      type: 'CLOSE_UNADVERTISED'
    }
  | {
      type: 'CLOSE_CHECKLISTSUCCESS'
    }
  | {
      type: 'OPEN_CHECKLISTSUCCESS'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }

type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'

type SIDEBAR_VIEWS = 'CART_VIEW' | 'CHECKOUT_VIEW' | 'PAYMENT_METHOD_VIEW'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_CHECKLIST': {
      return {
        ...state,
        displayChecklist: true,
      }
    }
    case 'CLOSE_CHECKLIST': {
      return {
        ...state,
        displayChecklist: false,
      }
    }
    case 'OPEN_DISCOUNT': {
      return {
        ...state,
        displayDiscount: true,
      }
    }
    case 'CLOSE_DISCOUNT': {
      return {
        ...state,
        displayDiscount: false,
      }
    }
    case 'OPEN_COMPETITOR': {
      return {
        ...state,
        displayCompetitor: true,
      }
    }
    case 'CLOSE_COMPETITOR': {
      return {
        ...state,
        displayCompetitor: false,
      }
    }
    case 'OPEN_UNADVERTISED': {
      return {
        ...state,
        displayUnadvertised: true,
      }
    }
    case 'CLOSE_UNADVERTISED': {
      return {
        ...state,
        displayUnadvertised: false,
      }
    }
    case 'OPEN_CHECKLISTSUCCESS': {
      return {
        ...state,
        displayChecklistSuccess: true,
      }
    }
    case 'CLOSE_CHECKLISTSUCCESS': {
      return {
        ...state,
        displayChecklistSuccess: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
  }
}

export const UIProvider: FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openSidebar = useCallback(
    () => dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch]
  )
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openChecklist = useCallback(
    () => dispatch({ type: 'OPEN_CHECKLIST' }),
    [dispatch]
  )
  const closeChecklist = useCallback(
    () => dispatch({ type: 'CLOSE_CHECKLIST' }),
    [dispatch]
  )
  const toggleChecklist = useCallback(
    () =>
      state.displayChecklist
        ? dispatch({ type: 'CLOSE_CHECKLIST' })
        : dispatch({ type: 'OPEN_CHECKLIST' }),
    [dispatch, state.displayChecklist]
  )
  const closeChecklistIfPresent = useCallback(
    () => state.displayChecklist && dispatch({ type: 'CLOSE_CHECKLIST' }),
    [dispatch, state.displayChecklist]
  )

  const openDiscount = useCallback(
    () => dispatch({ type: 'OPEN_DISCOUNT' }),
    [dispatch]
  )
  const closeDiscount = useCallback(
    () => dispatch({ type: 'CLOSE_DISCOUNT' }),
    [dispatch]
  )
  const toggleDiscount = useCallback(
    () =>
      state.displayDiscount
        ? dispatch({ type: 'CLOSE_DISCOUNT' })
        : dispatch({ type: 'OPEN_DISCOUNT' }),
    [dispatch, state.displayDiscount]
  )
  const closeDiscountIfPresent = useCallback(
    () => state.displayDiscount && dispatch({ type: 'CLOSE_DISCOUNT' }),
    [dispatch, state.displayDiscount]
  )

  const openCompetitor = useCallback(
    () => dispatch({ type: 'OPEN_COMPETITOR' }),
    [dispatch]
  )
  const closeCompetitor = useCallback(
    () => dispatch({ type: 'CLOSE_COMPETITOR' }),
    [dispatch]
  )
  const toggleCompetitor = useCallback(
    () =>
      state.displayCompetitor
        ? dispatch({ type: 'CLOSE_COMPETITOR' })
        : dispatch({ type: 'OPEN_COMPETITOR' }),
    [dispatch, state.displayCompetitor]
  )
  const closeCompetitorIfPresent = useCallback(
    () => state.displayCompetitor && dispatch({ type: 'CLOSE_COMPETITOR' }),
    [dispatch, state.displayCompetitor]
  )

  const openUnadvertised = useCallback(
    () => dispatch({ type: 'OPEN_UNADVERTISED' }),
    [dispatch]
  )
  const closeUnadvertised = useCallback(
    () => dispatch({ type: 'CLOSE_UNADVERTISED' }),
    [dispatch]
  )
  const toggleUnadvertised = useCallback(
    () =>
      state.displayUnadvertised
        ? dispatch({ type: 'CLOSE_UNADVERTISED' })
        : dispatch({ type: 'OPEN_UNADVERTISED' }),
    [dispatch, state.displayUnadvertised]
  )
  const closeUnadvertisedIfPresent = useCallback(
    () => state.displayUnadvertised && dispatch({ type: 'CLOSE_UNADVERTISED' }),
    [dispatch, state.displayUnadvertised]
  )

  const openChecklistSuccess = useCallback(
    () => dispatch({ type: 'OPEN_CHECKLISTSUCCESS' }),
    [dispatch]
  )
  const closeChecklistSuccess = useCallback(
    () => dispatch({ type: 'CLOSE_CHECKLISTSUCCESS' }),
    [dispatch]
  )
  const toggleChecklistSuccess = useCallback(
    () =>
      state.displayChecklistSuccess
        ? dispatch({ type: 'CLOSE_CHECKLISTSUCCESS' })
        : dispatch({ type: 'OPEN_CHECKLISTSUCCESS' }),
    [dispatch, state.displayChecklistSuccess]
  )
  const closeChecklistSuccessIfPresent = useCallback(
    () =>
      state.displayChecklistSuccess && dispatch({ type: 'CLOSE_CHECKLIST' }),
    [dispatch, state.displayChecklistSuccess]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )

  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openChecklist,
      closeChecklist,
      toggleChecklist,
      closeChecklistIfPresent,
      openDiscount,
      closeDiscount,
      toggleDiscount,
      closeDiscountIfPresent,
      openCompetitor,
      closeCompetitor,
      toggleCompetitor,
      closeCompetitorIfPresent,
      openUnadvertised,
      closeUnadvertised,
      toggleUnadvertised,
      closeUnadvertisedIfPresent,
      openChecklistSuccess,
      closeChecklistSuccess,
      toggleChecklistSuccess,
      closeChecklistSuccessIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setUserAvatar,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC = ({ children }) => (
  <UIProvider>{children}</UIProvider>
)
