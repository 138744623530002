import { productSpecsMetafieldsFragment } from '../common'

const getProductQuery = /* GraphQL */ `
query productByHandle($slug: String!) {
  productByHandle(handle: $slug) {
    id
    totalInventory
    availableForSale
    handle
    title
    vendor
    description
    descriptionHtml
    updatedAt
    seo {
      description
      title
    }
    ${productSpecsMetafieldsFragment}
    ${/** Manufacturer URL we want separate from the other specs */ ''}
    manufacturerUrl: metafield(namespace: "specs", key: "manufacturer_url") {
      value
    }
    callForPricing: metafield(namespace: "general", key: "call_for_pricing") {
      value
    }
    emailForPricing: metafield(namespace: "general", key: "email_for_pricing") {
      value
    }
    beatCompetitorsPrice: metafield( namespace: "general" key: "beat_competitors_price" ) {
      value
    }
    requestInstall: metafield( namespace: "general" key: "button_request_install" ) {
      value
    }
    discountOff: metafield( namespace: "general" key: "dollar_off" ) {
      value
    }
    unadvertisedPrice: metafield(namespace: "general", key: "unadvertised_special_price") {
      value
    }
    inStoreOnly: metafield(namespace: "general", key: "in_store_only") {
      value
    }
    discountDollar: metafield(namespace: "general", key: "discount_dollar") {
      value
    }
    discountPercentage: metafield(namespace: "general", key: "discount_percentage") {
      value
    }
    descriptionHtml
    options {
      id
      name
      values
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    variants(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          sku
          selectedOptions {
            name
            value
          }
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
            currencyCode
          },
          availableForSale
          quantityAvailable
        }
      }
    }
    media(first: 20) {
      edges {
        node {
          mediaContentType
          ... on Video {
            alt,
            sources {
              url,
              height,
              width
              mimeType
            }
          }
          ... on ExternalVideo {
            alt,
            embedUrl
          }
          ... on MediaImage {
            alt,
            image {
              originalSrc,
              width,
              height
            }
          }
        }
      }
    }
    collections(first: 10) {
      edges {
        node {
          id,
          handle,
          title,
          description,
          category: metafield(namespace: "taxonomy", key: "category") {
            value
          }
        }
      }
    }
  }
}
`

export default getProductQuery
