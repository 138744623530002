import { useHook, useSWRHook } from '../utils/use-hook'
import { ApiHooks, SWRHook } from '@common/types/hooks'

export type UseMenu<H extends SWRHook = SWRHook<any>> = ReturnType<H['useHook']>

const useMenu: UseMenu = () => {
  const hook = useHook((hooks: ApiHooks) => hooks.menu.useMenu)

  return useSWRHook({ ...hook })()
}

export default useMenu
