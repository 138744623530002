import { useMemo } from 'react'
import Cookies from 'js-cookie'
import useCustomer, { UseCustomer } from '@common/customer/use-customer'
import { SWRHook } from '@common/types/hooks'
import { Customer } from '@common/types/customer'

export type UseCustomerHookDescriptor = {
  fetcherInput: {
    customerCookie: string
  }
  /**
   * Don't think this is the right typing here, but we aren't getting warnings
   * because we aren't using the fetch method inside of fetcher
   */
  fetcherOutput: {
    node: Customer
  }
  data: Customer
}

export default useCustomer as UseCustomer<typeof handler>

export const handler: SWRHook<UseCustomerHookDescriptor> = {
  fetcherOptions: {
    // This is the SWR key, need for SWR even if we aren't querying anything
    query: 'get-customer',
  },
  // We don't use the API, so no need to use the fetch argument passed
  async fetcher({ input: { customerCookie } }) {
    const customerCookieDefault = '{"loggedIn": false}'
    const customerCookieDataString =
      Cookies.get(customerCookie) ?? customerCookieDefault
    const customerCookieDataParsed =
      customerCookieDataString !== undefined || customerCookie !== 'undefined'
        ? JSON.parse(customerCookieDataString)
        : JSON.parse(customerCookieDefault)

    return customerCookieDataParsed
  },
  useHook:
    ({ useData }) =>
    () => {
      const result = useData({
        swrOptions: {
          revalidateOnFocus: false,
        },
      })

      return useMemo(() => {
        return {
          ...result,
        }
      }, [result])
    },
}
