import { checkoutDetailsFragment } from '../common'

const getCheckout = /* GraphQL */ `
  query ($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...checkoutDetails
    }
  }
  ${checkoutDetailsFragment}
`

export default getCheckout
