export const checkoutDetailsFragment = /* GraphQL */ `
  fragment checkoutDetails on Checkout {
    id
    webUrl
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    completedAt
    createdAt
    taxesIncluded
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          variant {
            id
            sku
            availableForSale
            quantityAvailable
            title
            selectedOptions {
              name
              value
            }
            image {
              originalSrc
              altText
              width
              height
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            product {
              handle
            }
          }
          quantity
        }
      }
    }
  }
`
// TODO: Move to an environment variable or JSON file
// These currently need to match the metafield values in the Shopify Admin
const productSpecsMetafields = [
  {
    key: 'part_number',
    label: 'Part Number',
  },
  {
    key: 'height',
    label: 'Height (Inches)',
  },
  {
    key: 'width',
    label: 'Width (Inches)',
  },
  {
    key: 'length',
    label: 'Length (Inches)',
  },
  {
    key: 'power_source',
    label: 'Power Source',
  },
  {
    key: 'horsepower',
    label: 'Horsepower',
  },
  {
    key: 'voltage',
    label: 'Voltage',
  },
  {
    key: 'material',
    label: 'Material',
  },
  {
    key: 'color',
    label: 'Color',
  },
  {
    key: 'quantity_required',
    label: 'Quantity Required',
  },
  {
    key: 'manufacturer',
    label: 'Manufacturer',
  },
  {
    key: 'bin',
    label: 'Bin',
  },
  {
    key: 'bin_locations',
    label: 'Bin Locations',
  },
  {
    key: 'price_match',
    label: 'Price Match',
  },
  {
    key: 'best_price_available',
    label: 'Best Price Available',
  }
]
let productSpecsMetafieldsFragment: string = ''

productSpecsMetafields.forEach((spec) => {
  productSpecsMetafieldsFragment += `
  ${spec.key}: metafield(namespace: "specs", key: "${spec.key}") {
    value
  }
`
})

export { productSpecsMetafieldsFragment, productSpecsMetafields }
