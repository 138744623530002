const getAllProductsQuery = /* GraphQL */ `
  query getAllProducts(
    $pageCount: Int = 48
    $query: String = ""
    $sortKey: ProductSortKeys = RELEVANCE
    $reverse: Boolean = false
    $cursor: String = null
  ) {
    products(
      first: $pageCount
      query: $query
      sortKey: $sortKey
      reverse: $reverse
      after: $cursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          totalInventory
          availableForSale
          title
          vendor
          handle
          description
          descriptionHtml
          updatedAt
          options {
            id
            name
            values
          }
          callForPricing: metafield(
            namespace: "general"
            key: "call_for_pricing"
          ) {
            value
          }
          emailForPricing: metafield(
            namespace: "general"
            key: "email_for_pricing"
          ) {
            value
          }
          beatCompetitorsPrice: metafield(
            namespace: "general"
            key: "beat_competitors_price"
          ) {
            value
          }
          requestInstall: metafield(
            namespace: "general"
            key: "button_request_install"
          ) {
            value
          }
          discountOff: metafield(
            namespace: "general"
            key: "dollar_off"
          ) {
            value
          }
          unadvertisedPrice: metafield(
            namespace: "general"
            key: "unadvertised_special_price"
          ) {
            value
          }
          inStoreOnly: metafield(namespace: "general", key: "in_store_only") {
            value
          }
          discountDollar: metafield(
            namespace: "general"
            key: "discount_dollar"
          ) {
            value
          }
          discountPercentage: metafield(
            namespace: "general"
            key: "discount_percentage"
          ) {
            value
          }
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
          }
          variants(first: 250) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                title
                sku
                selectedOptions {
                  name
                  value
                }
                priceV2 {
                  amount
                  currencyCode
                }
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                availableForSale
                quantityAvailable
              }
            }
          }
          media(first: 1) {
            edges {
              node {
                mediaContentType
                ... on MediaImage {
                  alt
                  image {
                    originalSrc
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default getAllProductsQuery
