import { useMemo } from 'react'
import { SWRHook } from '@common/types/hooks'
import useMenu, { UseMenu } from '@common/menu/use-menu'
import { Menu } from '@common/types/menu'

export default useMenu as UseMenu<typeof handler>

export type UseMenuHookDescriptor = {
  fetcherInput: {
    customerCookie: string
  }
  fetcherOutput: {
    node: Menu[]
  }
  data: Menu[]
}

export const handler: SWRHook<UseMenuHookDescriptor> = {
  fetcherOptions: {
    query: 'get-menu',
  },
  async fetcher({ options }) {
    /**
     * This is not ideal, we probably should create a custom fetcher for this, but
     * this is okay for now
     */
    // TODO: Move to ENV
    const response = await fetch(
      'https://shop.tspoolsupply.com/pages/api-link-lists'
    )

    // TODO: Need typing/dto/normalizer for this data
    const data = await response.json()

    return data?.linklists ?? []
  },
  useHook:
    ({ useData }) =>
    () => {
      const result = useData({
        swrOptions: {
          revalidateOnFocus: false,
        },
      })

      return useMemo(() => {
        return {
          ...result,
        }
      }, [result])
    },
}
