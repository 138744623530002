import { productSpecsMetafieldsFragment } from '../common'

const getCollectionQuery = /* GraphQL */ `
  query collectionByHandle($slug: String!, $productPageCount: Int = 48, $productCursor: String = null) {
    collectionByHandle(handle: $slug) {
      id,
      handle,
      title,
      description,
      image {
        originalSrc
      },
      category: metafield(namespace: "taxonomy", key: "category") {
        value
      },
      color: metafield(namespace: "style", key: "color") {
        value
      },
      sort_order: metafield(namespace: "taxonomy", key: "sort_order") {
        value
      },
      products(first: $productPageCount, after: $productCursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
              id
              totalInventory
              availableForSale
              handle
              title
              vendor
              description
              descriptionHtml
              ${productSpecsMetafieldsFragment}
              descriptionHtml
              updatedAt
              seo {
                description
                title
              }
              callForPricing: metafield(namespace: "general", key: "call_for_pricing") {
                value
              }
              emailForPricing: metafield(namespace: "general", key: "email_for_pricing") {
                value
              }
              beatCompetitorsPrice: metafield( namespace: "general" key: "beat_competitors_price" ) {
                value
              }
              requestInstall: metafield( namespace: "general" key: "button_request_install" ) {
                value
              }
              discountOff: metafield( namespace: "general" key: "dollar_off" ) {
                value
              }
              unadvertisedPrice: metafield(namespace: "general", key: "unadvertised_special_price") {
                value
              }
              inStoreOnly: metafield(namespace: "general", key: "in_store_only") {
                value
              }
              discountDollar: metafield(namespace: "general", key: "discount_dollar") {
                value
              }
              discountPercentage: metafield(namespace: "general", key: "discount_percentage") {
                value
              }
              options {
                id
                name
                values
              }
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
              variants(first: 250) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  node {
                    id
                    title
                    sku
                    selectedOptions {
                      name
                      value
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    compareAtPriceV2 {
                      amount
                      currencyCode
                    },
                    availableForSale
                    quantityAvailable
                  }
                }
              }
              media(first: 1) {
                edges {
                  node {
                    mediaContentType
                    ... on MediaImage {
                      alt
                      image {
                        originalSrc
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
        }
      }
    }
  }
`

export default getCollectionQuery
