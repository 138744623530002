import { handler as useAddItem } from './cart/use-add-item'
import { handler as useCart } from './cart/use-cart'
import { handler as useRemoveItem } from './cart/use-remove-item'
import { handler as useUpdateItem } from './cart/use-update-item'
import { handler as useCustomer } from './customer/use-customer'
import { handler as useMenu } from './menu/use-menu'

export const shopifyHooks = {
  cart: {
    useAddItem,
    useCart,
    useRemoveItem,
    useUpdateItem,
  },
  customer: {
    useCustomer,
  },
  menu: {
    useMenu,
  },
}
