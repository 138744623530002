import { useHook, useSWRHook } from '../utils/use-hook'
import { ApiHooks, SWRHook } from '@common/types/hooks'
import { useApiProvider } from '@common'

export type UseCustomer<H extends SWRHook = SWRHook<any>> = ReturnType<
  H['useHook']
>

const useCustomer: UseCustomer = () => {
  const hook = useHook((hooks: ApiHooks) => hooks.customer.useCustomer)
  const { customerCookie } = useApiProvider()

  const fetcherWrapper: typeof hook.fetcher = (context: any) => {
    context.input.customerCookie = customerCookie

    return hook.fetcher(context)
  }

  return useSWRHook({ ...hook, fetcher: fetcherWrapper })()
}

export default useCustomer
