import { Collection } from './collection'

export enum ProductMediaType {
  Video = 'video',
  ExternalVideo = 'external_video',
  Image = 'image',
}

export interface ProductMedia {
  type: ProductMediaType
  alt?: string
}

export interface ProductImage extends ProductMedia {
  url: string
  width?: number
  height?: number
}

export type ProductVideoSource = {
  url: string
  mimeType: string
  height?: number
  width?: number
}

export interface ProductVideo extends ProductMedia {
  sources: ProductVideoSource[]
}

export interface ProductExternalVideo extends ProductMedia {
  url: string
}

export interface ProductPrice {
  value: number
  currencyCode: 'USD' | 'EUR' | string
}

export interface CompareProductPrice {
  value: number
  currencyCode: 'USD' | 'EUR' | string
}

export interface ProductOptionValues {
  label: string
}

export interface ProductOption {
  id: string
  displayName: string
  values: ProductOptionValues[]
}

export interface ProductVariant {
  id: string
  name: string
  sku: string
  image?: ProductImage
  requiresShipping: boolean
  price: number
  compareprice: number
  listPrice: number
  options: ProductOption[]
  availableForSale: boolean
  quantityAvailable: number
}

export interface Product {
  descriptionHtml: string
  width: string | undefined
  height: string | undefined
  id: string
  name: string
  availableForSale: boolean
  totalInventory: number
  description: string
  slug: string
  path: string
  media: ProductMedia[]
  price: ProductPrice
  compareprice: CompareProductPrice
  options: ProductOption[]
  variants: ProductVariant[]
  manufacturerUrl: string
  collections: Collection[]
  notAvailableOnline: null | ProductNotAvailableOnline
  specialPrice: null | ProductSpecialPrice
  hasDiscountOff: string
  hasBeatCompetitorsPrice: boolean
  hasRequestInstall: boolean
  updatedAt: Date
  unadvertisedPrice: ProductSpecialPrice | boolean
  seo: {
    title: string
    description: string
  }
}

export type ProductKeys = keyof Product

export interface ProductSpec {
  value: string | number
  label: string
}

export enum ProductNotAvailableOnlineReason {
  CallForPrice = 'Call For Pricing',
  EmailForPrice = 'In-Store Only',
  BeatCompetitorsPrice = 'Beat Competitor\'s Price*',
  DiscountOff = 'Discount OFF',
  InStoreOnly = 'Available In-Store Only',
  DiscountDollar = '* Off',
  DiscountPercentage = '*% Off',
}

export enum ProductNotAvailableOnlineContactType {
  Call = 'call',
  Email = 'email',
}

export interface ProductNotAvailableOnline {
  reasonText: ProductNotAvailableOnlineReason
  contactType: ProductNotAvailableOnlineContactType
  showPrice: boolean
}

export interface ProductSpecialPrice {
  specialPrice: boolean
}
